@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap");
@import url("https://use.typekit.net/lhq7icz.css");
@import url("https://www.w3schools.com/w3css/4/w3.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");


$light-gray: #707070;

$progress-0: 0%;
$progress-10: 10%;
$progress-20: 20%;
$progress-30: 30%;
$progress-40: 40%;
$progress-50: 50%;
$progress-60: 60%;
$progress-70: 70%;
$progress-80: 80%;
$progress-90: 90%;
$progress-100: 100%;

.cartLogo{
    width: 40px;
    height: 40px;
    transform: rotate(90deg);
}

.home-div{
    margin-left: -5vw;

}

.App{
    z-index: 10 !important;
}

.root{
    z-index: 10;

}

#root{
    background-image: url("/images/background.png");
    height: 105vh;
    background-attachment: fixed !important;
    background-position: center !important;

}

.background-image{
    z-index: 0;
    height: 105vh;
    position: fixed;

}



*{
    z-index: 10;
    font-family: "Brandon Grotesque", "Poppins", sans-serif;
    box-sizing: border-box;
}

body {
  min-height: 100vh;
}

.underline{
    color: #000000;
    text-decoration: underline;

}



.app {
  display: flex;
  justify-content: center;


  .container {
    width: 1024px;

    .link {
      text-decoration: none;
      color: inherit;
      padding: 0px 0px 0px 0px !important;
      margin: 0px 20px 0px 20px !important;
      font-family: Didot,Didot LT STD,Hoefler Text,Garamond,Times New Roman,serif;

    }

    .span-link {
      text-decoration: none;
      color: inherit;
      padding: 0px 0px 0px 0px !important;
      margin: 0px 0px 0px 0px !important;

    }

    // LOGIN & REGISTER
    .auth {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
      background-color: #f3f3f3;

      h1 {
        font-size: 30px;
        color: $light-gray;
        margin-bottom: 20px;
        margin-top: 10px;
      }

      form {
        display: flex;
        flex-direction: column;
        padding: 50px;
        background-color: white;
        border-radius: 4px;
        width: 200px;
        gap: 20px;

        input {
          padding: 10px;
          border: none;
          border-bottom: 1px solid gray;
        }

        button {
          padding: 10px;
          border: none;
          background-color: #333;
          border-radius: 4px;
          color: white;
          cursor: pointer;
        }

        p {
          font-size: 12px;
          color: red;
          text-align: center;
        }

        span {
          font-size: 12px;
          text-align: center;
        }
      }
    }
    //NEW NAV

    .navigation-new {
      position: fixed;
      width: 500px;
      height: 40px;
      border-radius: 40px;
      background-color: #fff;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: center;
      left: calc(50% - 250px);
      top: 5vh;
      z-index: 110;
    }

    .fixed-nav{
        position: fixed;
    }

    .navigation-new ul {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      padding-left: 0px;
    }
    .navigation-new ul li {
      position: relative;
      list-style: none;
      width: 100%;
      height: 40px;
      z-index: 1;
    }

    .navigation-new ul li a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      color: #333;
      font-weight: 500;
      text-decoration: none;
    }

    .navigation-new ul li a .icon-new {
      position: relative;
      display: block;
      line-height: 38px;
      text-align: center;
      transition: 0s;
      text-decoration: none;
    }
    .navigation-new ul li.active a .icon-new {
      color: #fff;
    }
    .navigation-new ul li a .icon-new i {
      font-size: 12px;
      font-style: normal;
    }

    //BASIC
    .navigation-new ul .indicator-new {
      position: absolute;
      left: 0;
      width: 65px;
      height: 40px;
      transition: all 0.5s;
      z-index: 0;

    }
    .navigation-new ul .indicator-new::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 95%;
      transform: translate(-50%, -50%);
      background-color: #333;
      width: 120px;
      height: 35px;
      border-radius: 20px;
      transition: 0s;
    }
    .navigation-new ul li:nth-child(1).active ~ .indicator-new {
      transform: translateX(calc(125px * 0));
    }
    .navigation-new ul li:nth-child(2).active ~ .indicator-new {
      transform: translateX(calc(125px * 1));
    }
    .navigation-new ul li:nth-child(3).active ~ .indicator-new {
      transform: translateX(calc(125px * 2));
    }
    .navigation-new ul li:nth-child(4).active ~ .indicator-new {
      transform: translateX(calc(125px * 3));
    }

    //BASIC TWO
    .navigation-new ul .indicator-new-two {
      position: absolute;
      left: 0;
      width: 65px;
      height: 40px;
      transition: all 0.5s;
      z-index: 0;

    }
    .navigation-new ul .indicator-new-two::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 95%;
      transform: translate(calc(-50% + 125px), -50%);
      background-color: #333;
      width: 120px;
      height: 35px;
      border-radius: 20px;
      transition: 0s;
    }
    .navigation-new ul li:nth-child(1).active ~ .indicator-new-two {
      transform: translateX(calc(-125px));
    }
    .navigation-new ul li:nth-child(2).active ~ .indicator-new-two {
      transform: translateX(calc(125px * 0));
    }
    .navigation-new ul li:nth-child(3).active ~ .indicator-new-two {
      transform: translateX(calc(125px * 1));
    }
    .navigation-new ul li:nth-child(4).active ~ .indicator-new-two {
      transform: translateX(calc(125px * 2));
    }
    //BASIC THREE
    .navigation-new ul .indicator-new-three {
      position: absolute;
      left: 0;
      width: 65px;
      height: 40px;
      transition: all 0.5s;
      z-index: 0;

    }
    .navigation-new ul .indicator-new-three::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 95%;
      transform: translate(calc(-50% + 250px), -50%);
      background-color: #333;
      width: 120px;
      height: 35px;
      border-radius: 20px;
      transition: 0s;
    }
    .navigation-new ul li:nth-child(1).active ~ .indicator-new-three {
      transform: translateX(calc(-125px*2));
    }
    .navigation-new ul li:nth-child(2).active ~ .indicator-new-three {
      transform: translateX(calc(-125px));
    }
    .navigation-new ul li:nth-child(3).active ~ .indicator-new-three {
      transform: translateX(calc(125px * 0));
    }
    .navigation-new ul li:nth-child(4).active ~ .indicator-new-three {
      transform: translateX(calc(125px * 1));
    }
    //BASIC FOUR
    .navigation-new ul .indicator-new-four {
      position: absolute;
      left: 0;
      width: 65px;
      height: 40px;
      transition: all 0.5s;
      z-index: 0;

    }
    .navigation-new ul .indicator-new-four::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 95%;
      transform: translate(calc(-50% + 375px), -50%);
      background-color: #333;
      width: 120px;
      height: 35px;
      border-radius: 20px;
      transition: 0s;
    }
    .navigation-new ul li:nth-child(1).active ~ .indicator-new-four {
      transform: translateX(calc(-125px*3));
    }
    .navigation-new ul li:nth-child(2).active ~ .indicator-new-four {
      transform: translateX(calc(-125px*2));
    }
    .navigation-new ul li:nth-child(3).active ~ .indicator-new-four {
      transform: translateX(calc(-125px*1));
    }
    .navigation-new ul li:nth-child(4).active ~ .indicator-new-four {
      transform: translateX(calc(-125px*0));
    }
    // NAVBAR
    .navbar {
        margin-bottom: 20vh;
        width: 50vw;

      .container {
        padding: 0px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        background-color: rgba(0,0,0,0);
        margin-top: 5vh;
        z-index: 100 !important;
        width: 10vw;


        .logo {
          img {
            width: 50px;
          }
        }

        .main-logo{
            margin-left: -8.25vw !important;
            margin-top: 88.6vh;
            width: 40px !important;
        }

        .socialLogos{
            margin-left: -8.25vw !important;
            margin-top: 89vh;
            width: 30px;
        }

        .links {
          display: flex;
          gap: 10px;
          position: relative;
          right: -25%;
          transform: rotate(-90deg);
          direction: rtl;
          clear: both;
          max-height: 0;
          transition: max-height .2s ease-out;


          & > *:not(:last-child):hover {
            border-bottom: 1px solid #f3f3f3;
          }
        }

        h6 {
          font-size: 16px;
          font-weight: 300;
        }

        span {
          cursor: pointer;
        }

        .write {
          background-color: #f3f3f3;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 300;
          border: 1px solid #f3f3f3; // to prevent items move when hover because the extra pixel of the border when hover

          &:hover {
            color: #333;
            background-color: #f3f3f3;
            border: 1px solid #333;
          }
        }
      }
    }

    //HAMBURGER
    /* header */

    .header-two{
        margin-right: -21vw !important;
        margin-left: calc(90vw - 420px) !important;
        margin-top: 230px !important;
        position: fixed;
        height: 100vw;
        align-items: right;
    }

    .header-two-logged-in{
        margin-top: 300px !important;
    }

    .header-two-logged-out{
        margin-top: 200px !important;
    }

    .h6-two{
        margin-left: 15px;
    }

    .h6-three{
        margin-left: 15px !important;
        margin-right: 15px !important;
    }


    .header-two ul {
      overflow: hidden;
      list-style: none;
      transform: rotate(-90deg);
      direction: rtl;


    }

    .header-two li a {
      display: block;
      text-decoration: none;


    }

    h6{
      font-family: Didot,Didot LT STD,Hoefler Text,Garamond,Times New Roman,serif;
      font-size: 16px;
      font-weight: lighter;
    }

    h4{
      font-family: Didot,Didot LT STD,Hoefler Text,Garamond,Times New Roman,serif;
      font-size: 16px;
      font-weight: lighter;
      line-height: .9;
    }

    h1{
        font-family: Didot,Didot LT STD,Hoefler Text,Garamond,Times New Roman,serif;
    }

    p{
        font-weight: lighter;
        margin-left: 20px;
    }

    /* menu */

    .header-two .menu {
      width: 80vh;
      transition: max-height .2s ease-out;
    }

    /* menu icon */

    .header-two .menu-icon {
      cursor: pointer;
      display: inline-block;
      float: right;
      position: relative;
      user-select: none;
      margin-top: -18.5vh !important;
      padding-right: calc(-150vw) !important;
      z-index: 100;
    }

    .header-two .menu-icon .navicon {
      background: #333;
      display: block;
      height: 2px;
      position: relative;
      transition: background .2s ease-out;
      width: 18px;
    }

    .header-two .menu-icon .navicon:before,
    .header-two .menu-icon .navicon:after {
      background: #333;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      transition: all .2s ease-out;
      width: 100%;
    }

    .header-two .menu-icon .navicon:before {
      top: 5px;
    }

    .header-two .menu-icon .navicon:after {
      top: -5px;
    }

    /* menu btn */

    .header-two .menu-btn {
      display: none;
    }

    .header-two .menu-btn:checked ~ .menu {
      max-height: 240px;
    }

    .header-two .menu-btn:checked ~ .menu-icon .navicon {
      background: transparent;
    }

    .header-two .menu-btn:checked ~ .menu-icon .navicon:before {
      transform: rotate(-45deg);
    }

    .header-two .menu-btn:checked ~ .menu-icon .navicon:after {
      transform: rotate(45deg);
    }

    .header-two .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
    .header-two .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
      top: 0;
    }

    /* 48em = 768px */

    @media (min-width: 4em) {
      .header-two li {
        float: left;
      }
      .header-two li a {
        padding: 20px 30px;
      }
      .header-two .menu {
        clear: none;
        float: right;
        max-height: none;
      }
      .header-two .menu-icon {
        display: none;
      }


    }
    @media (max-width: 48em) {
          .navigation-new{
            transform: scale(.9);
          }

          .menu{
            margin-top: 100px;
            transition: margin 1000ms;
          }


        }




    // FOOTER
    footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 20px;
      margin-top: 200px;
      border-top: 1px solid #ccc;
      margin-bottom: 20px;
    }

    footer span {
      margin-top: 10px;
      font-size: 18px;
      color: #999;
    }

    footer img {
      max-width: 75px;
      margin-bottom: 10px;
    }

    .buttons-container{
        margin-top: 80vh !important;
    }

    .paypal-buttons{
        margin-top: 20vh !important;
    }

    .height-100{
       height: 300vh !important
    }

    .dom-ready{
        margin-top: 200vh;
    }

    .homepage{
        list-style-type: none;
        margin-left: 1.5vw;
        padding: 0;
        height: 80vh;

        li{
            float: left;
            padding-right: 1vw;

            a{
                display: block;
                text-decoration: none;
                font-family: Didot,Didot LT STD,Hoefler Text,Garamond,Times New Roman,serif;
            }

            img{
                width: 25vw;
                height: 80.6vh;
                border-radius: 5px;
                transition: 1s linear;
                position: relative;
            }

            img:hover{
                filter: blur(5px);
                filter: brightness(80%);
                opacity: 0;
                transition: 1s linear;
                border: 2px #333;
            }

        }
    }

    .store-cont{
        ul{
            list-style-type: none;
            display: table;
            margin: 0 auto;

            li{
            text-align: center;


                a{
                    text-decoration: none;
                }

            }
        }
    }

    // HOME
    .post-list{
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;

    }

    .post-list li{
        float: left;
        padding-right: 5vw;
    }

    .post-list li a{
        display: block;
        text-decoration: none;
        font-family: Didot,Didot LT STD,Hoefler Text,Garamond,Times New Roman,serif;

    }

    .post-list h1{
        margin-top: 25px;
    }

    .home {
        margin-top: 20vh;


      .posts {
        display: flex;
        flex-direction: column;
        gap: 150px;

        .post {
          display: flex;
          gap: 40px;

          &:nth-child(2n + 1) {
            flex-direction: row-reverse;
          }

          .post-img {
            flex: 3;
            position: relative;
            width: 50vw;
            border-radius: 5px;

            &::after {
              content: "";
              width: 100%;
              height: auto;
              background-color: #f3f3f3;
              position: absolute;
              top: 0px;
              left: 0px;
              z-index: -1;
            }

            img {
              width: 100%;
              max-height: 400px;
              object-fit: cover;
              border-radius: 5px;
            }
          }

          .desc-post-img {
              flex: 3;
              position: relative;
              height: 100%;
              border-radius: 5px;

              &::after {
                content: "";
                width: 100%;
                height: auto;
                background-color: #f3f3f3;
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: -1;
              }

              img {
                width: 100%;
                max-height: 400px;
                object-fit: cover;
                border-radius: 5px;
              }
            }

          .content {
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 30px 30px 30px 30px

            h1 {
              font-size: 48px;
            }

            p {
              margin-top: 20px;
              font-size: 18px;
            }

            button {
              width: max-content;
              font-size: 16px;
              margin-top: 40px;
              padding: 10px 20px;
              border: none;
              cursor: pointer;
              background-color: rgba(0, 0, 0, 0);
              border: 1px solid #333;
              color: #333;

              &:hover {
                border: 1px solid #333;
                background-color: #333;
                color: #f3f3f3;
              }
            }
          }
        }
      }
    }

    // SINGLE
    .single {
      display: flex;
      gap: 50px;

      .content {
        flex: 5;
        display: flex;
        flex-direction: column;
        gap: 30px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }

        .user {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
            border-radius: 5px;
          }
        }

        span {
          font-weight: bold;
          margin-left: 20px;
        }

        .edit {
          display: flex;
          gap: 5px;

          img {
            width: 20px;
            height: 20px;
            cursor: pointer;
            border-radius: 5px;
          }
        }

        h1 {
          font-size: 42px;
          color: #333;
        }

        p {
          text-align: justify;
          line-height: 30px;
          text-wrap: wrap;
        }
      }

      .menu {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 25px;

        h1 {
          font-size: 20px;
          color: #555;
        }

        .post {
          display: flex;
          flex-direction: column;
          gap: 10px;

          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 5px;
          }

          h2 {
            color: #555;
          }

          button {
            width: max-content;
            padding: 7.5px 15px;
            border: none;
            cursor: pointer;
            background-color: #f3f3f3;
            border: 1px solid #333;
            color: #333;

            &:hover {
              border: 1px solid #333;
              background-color: #333;
              color: #f3f3f3;
            }
          }
        }
      }
    }

    //INSTAGRAM

    .insta-one{
        background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:540px; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);
    }

    .insta-two{
        padding:16px;
    }

    .insta-three{
        background:#FFFFFF; line-height:0; padding:0 0; text-align:center; text-decoration:none; width:100%;
    }

    .insta-four{
         display: flex; flex-direction: row; align-items: center;
    }

    .insta-five{
        background-color: #F4F4F4; border-radius: 50%; flex-grow: 0; height: 40px; margin-right: 14px; width: 40px;
    }

    .insta-six{
        display: flex; flex-direction: column; flex-grow: 1; justify-content: center;
    }

    .insta-seven{
        background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; margin-bottom: 6px; width: 100px;
    }

    .insta-eight{
         background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; width: 60px;
    }

    .insta-nine{
        padding: 19% 0;
    }

    .insta-ten{
        display:block; height:50px; margin:0 auto 12px; width:50px;
    }

    .insta-ten-pointfive{
        width: "50px"; height: "50px"; viewBox: "0 0 60 60";
    }

    .insta-ten-pointsix{
        stroke:"none"; stroke-width:"1"; fill:"none"; fill-rule:"evenodd";
    }

    .insta-ten-pointseven{
        fill: "#000000";
    }

    .insta-eleven{
        padding-top: 8px;
    }

    .insta-twelve{
        color:#3897f0; font-family:Arial,sans-serif; font-size:14px; font-style:normal; font-weight:550; line-height:18px;
    }

    .insta-thirteen{
        padding: 12.5% 0;
    }

    .insta-fourteen{
        display: flex; flex-direction: row; margin-bottom: 14px; align-items: center;
    }

    .insta-fifteen{
        background-color: #F4F4F4; border-radius: 50%; height: 12.5px; width: 12.5px; transform: translateX(0px) translateY(7px);
    }

    .insta-sixteen{
        background-color: #F4F4F4; height: 12.5px; transform: rotate(-45deg) translateX(3px) translateY(1px); width: 12.5px; flex-grow: 0; margin-right: 14px; margin-left: 2px;
    }

    .insta-seventeen{
        background-color: #F4F4F4; border-radius: 50%; height: 12.5px; width: 12.5px; transform: translateX(9px) translateY(-18px);
    }

    .insta-eighteen{
        margin-left: 8px;
    }

    .insta-nineteen{
        background-color: #F4F4F4; border-radius: 50%; flex-grow: 0; height: 20px; width: 20px;
    }

    .insta-twenty{
        width: 0; height: 0; border-top: 2px solid transparent; border-left: 6px solid #f4f4f4; border-bottom: 2px solid transparent; transform: translateX(16px) translateY(-4px) rotate(30deg);
    }

    .insta-twenty-one{
        margin-left: auto;
    }

    .insta-twenty-two{
        width: 0px; border-top: 8px solid #F4F4F4; border-right: 8px solid transparent; transform: translateY(16px);
    }

    .insta-twenty-three{
        background-color: #F4F4F4; flex-grow: 0; height: 12px; width: 16px; transform: translateY(-4px);
    }

    .insta-twenty-four{
        width: 0; height: 0; border-top: 8px solid #F4F4F4; border-left: 8px solid transparent; transform: translateY(-4px) translateX(8px);
    }

    .embedwindow{
         width: "800px";
         height: "600px";
         overflow:auto;
         border:5px;
    }
}

.container {
  margin: auto;
  width: 80%;
}

.cart-move{
    position: fixed;
    height: 100vh;
    margin-right: 42vh;
    margin-top: calc(80vw - 120px);
}

.badge {
    background-color: #333;
    border-radius: 10px;
    color: white;
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding: 3px 7px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    transform: rotate(90deg);
    margin: 0px 20px 20px 0px;
}

.badge-two {
    background-color: #333;
    border-radius: 10px;
    color: white;
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding: 3px 7px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    margin: 0px 20px 20px 0px;
}

.shopping-cart {
  margin-top: calc(155vw - 450px);;
  margin-right: 10vh;
  float: right;
  background: white;
  font-family: didot;
  color: #333;
  width: 320px;
  position: fixed;
  border-radius: 3px;
  padding: 20px;
  transform: rotate(90deg);


  .shopping-cart-header {
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 15px;

    .shopping-cart-total {
      float: right;
    }
  }

  .shopping-cart-items {

    padding-top: 20px;

      li {
        margin-bottom: 18px;
      }

    img {
      float: left;
      margin-right: 12px;
      border-radius: 5px;
    }

    .item-name {
      display: block;
      padding-top: 10px;
      font-size: 16px;
    }

    .item-price {
      color: #333;
      margin-right: 8px;
    }

    .item-quantity {
      color: #ABB0BE;
    }

    .clearfix{
      list-style: none;
    }
  }

}

.shopping-cart:after {
	bottom: 100%;
	left: 89%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-width: 8px;
	margin-left: -8px;
}

.cart-icon {
  color: #515783;
  font-size: 24px;
  margin-right: 7px;
  float: left;
}

.button {
  background: white;
  color: #333;
  border: .5px solid #333;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  display: block;
  border-radius: 5px;
  font-size: 16px;
  margin: 5px 0 15px 0;
  font-family: didot;

  &:hover {
    background: #333;
    color: white;
  }

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.store-cont{
    background-color: white;
    text-align: center;
    border-radius: 5px;
}





    // PROGRESS
    .meter-cont{
        width: 100%;
        height: 100px !important;
        padding-top: 20vh !important;
        display: grid;
        grid-template-columns: auto auto auto;

    }

    .progress-cont{
        margin-bottom: 20vh !important;
        height: 100px !important;
    }
    .meter {
      height: 100px !important; /* Can be anything */
      background: #555;
      border-radius: 25px;
      padding: 10px;
      box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
      width: 100% !important;
      margin-top: 20vh !important;
    }

    .progress-0{
      width: $progress-0;
    }
    .progress-10{
      width: $progress-10;
    }
    .progress-20{
      width: $progress-20;
    }
    .progress-30{
      width: $progress-30;
    }
    .progress-40{
      width: $progress-40;
    }
    .progress-50{
      width: $progress-50;
    }
    .progress-60{
      width: $progress-60;
    }
    .progress-70{
      width: $progress-70;
    }
    .progress-80{
      width: $progress-80;
    }
    .progress-90{
      width: $progress-90;
    }
    .progress-100{
      width: $progress-100;
    }

    .meter > span {
      display: block;
      height: 100%;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: rgb(43, 194, 83);
      background-image: linear-gradient(
        center bottom,
        rgb(43, 194, 83) 37%,
        rgb(84, 240, 84) 69%
      );
      box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
        inset 0 -2px 6px rgba(0, 0, 0, 0.4);
      position: absolute;
      overflow: hidden;
    }
    .meter > span:after,
    .animate > span > span {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
      );
      z-index: 1;
      background-size: 50px 50px;
      animation: move 2s linear infinite;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      overflow: hidden;
    }

    .animate > span:after {
      display: none;
    }

    @keyframes move {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: 50px 50px;
      }
    }


    //Socials

    //instagram
    .instagram-div{
       margin-left: 50%;
       width: 300%;
       background-color: #fff;
       transform: scale(1.7);

    }

    //twitter
    .twitter-div{
        margin: auto;
        width: 50%;
        transform: scale(1.7);
    }

    .twitter-tweet{
        width: 30% !important;
    }

    //tiktok
    .tiktok-div{
        margin: auto;
        margin-left: 31.5vw;
        width: 320px;
        border-radius: 10px;
    }


    // WRITE
    .add {
      margin-top: 20px;
      display: flex;
      gap: 20px;
      margin-left: 15vw !important; //here
      width: 50vw !important;


      .content {
        flex: 5;
        display: flex;
        flex-direction: column;
        gap: 20px;

        input {
          padding: 10px;
          border: 1px solid lightgray;
        }

        .editor-container {
          height: 400px;
          overflow: scroll;
          border: 1px solid lightgray;

          .editor {
            height: 100%;
            border: none;
          }
        }
      }

      .menu {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .item {
        padding: 10px;
        border: 1px solid lightgray;
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 12px;
        color: #555;

        h1 {
          font-size: 20px;
        }

        .file {
          text-decoration: underline;
          cursor: pointer;
        }

        .buttons {
          display: flex;
          justify-content: space-between;

          :first-child {
            cursor: pointer;
            color: #333;
            background-color: white;
            border: 1px solid #333;
            padding: 3px 5px;

            &:hover {
              background-color: #333;
              color: #f3f3f3;
              border: 1px solid #f3f3f3;
            }
          }

          :last-child {
            cursor: pointer;
            color: white;
            background-color: #333;
            border: 1px solid white;
            padding: 3px 5px;
          }
        }

        .category {
          display: flex;
          align-items: center;
          gap: 5px;
          color: #333;
        }
      }
    }
  }
}
